<template>
  <div class="bigLst">
    <div style="margin:20px">
      <img src="@/assets/whale-club-logo.png" style="height: 45px" alt="" />
    </div>
    <div class="login-box">
      <p class="login-tit">Login</p>
      <div class="from-con">
        <el-form
          :model="ruleForm"
          status-icon
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="User Name" prop="userName">
            <el-input
              v-model="ruleForm.userName"
              placeholder="Please enter the user name"
            ></el-input>
          </el-form-item>
          <el-form-item label="Password" prop="pass">
            <el-input
              type="password"
              v-model="ruleForm.pass"
              autocomplete="off"
              placeholder="Please input a password"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="info"
              round
              @click="submitForm()"
              class="info-btn-sub"
              >Sign in now</el-button
            >
            <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { login } from "@/api/login";
import { setToken } from "@/utils/auth";
export default {
  data() {
    return {
      ruleForm: {
        pass: "",
        userName: "",
      },
    };
  },
  created() {
  },
  methods: {
    //提交
    submitForm() {
      let that = this;
      if (that.ruleForm.userName == "" || that.ruleForm.userName == null) {
        that.$message.error("Please enter the account number");
      } else if (that.ruleForm.pass == "" || that.ruleForm.pass == null) {
        that.$message.error("Please input a password");
      } else {
        let username = that.ruleForm.userName;
        let password = that.ruleForm.pass;
        login(username, password).then((res) => {
          // console.log(res);
          if (res.code == 200) {
            setToken(res.token);
            that.$router.push("/eForm");
          }
        });
      }
    },
  },
};
</script>
<style>
body {
  /* background-color: #000; */
  /* color: #fff; */
  margin: 0;
  padding: 0;
}
</style>
<style scoped>
.bigLst{
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: #000; */
}
.from-con /deep/ .el-form-item__label {
  /* color: #fff; */
}
.from-con /deep/ .el-input__inner {
  /* background-color: #000000; */
  border-radius: inherit;
  border: none;
  border-bottom: 1px solid #000000;
  /* color: #fff; */
}
</style>
<style lang="scss" scoped>
.login-box {
  width: 40%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1);
  .login-tit {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
  .info-btn-sub {
    width: 100%;
  }
  .el-button--info {
    color: #fff;
    background-color: #000000;
    border-color: #8f5f09;
  }
}
</style>