import axios from 'axios'
import { Notification, MessageBox, Message,Loading } from 'element-ui'
// import store from '@/store'
import { getToken, removeToken} from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import { tansParams } from "@/utils/ruoyi";
import cache from '@/plugins/cache'

//开始加载动画
let loading;
// var loadingNum=0 //初始化接口数量
export function startLoading() {
    loading  = Loading.service({
        lock:true, //是否锁定
        text:'Loading...',//加载中需要显示的文字
        // background:'rgba(0,0,0,.7)',//背景颜色
    });
}

// 是否显示重新登录
export let isRelogin = { show: false };

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 20000
})

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url;                  // 请求地址
      const s_data = sessionObj.data;                // 请求数据
      const s_time = sessionObj.time;                // 请求时间
      const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = 'Data is being processed, please do not submit repeatedly';
        console.warn(`[${s_url}]: ` + message)
        return Promise.reject(new Error(message))
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    // 二进制数据则直接返回
    if(res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer'){
      return res.data
    }
    if (code === 401) {
      if (!isRelogin.show) {
        isRelogin.show = true;
        MessageBox.confirm('The login status has expired. You can continue to stay on the page or log in again', 'System Tips', {
          confirmButtonText: 'Re login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      ).then(() => {
        isRelogin.show = false;
        removeToken();//清空token
        window.location.reload()
        this.$router.push({path:'/login'})
        // this.$router.push('/login')//跳轉到登錄頁面 重新登錄
        // store.dispatch('LogOut').then(() => {
        //   location.href = '/index';
        // })
      }).catch(() => {
        isRelogin.show = false;
      });
    }
      return Promise.reject('Invalid session, or the session has expired, please log in again。')
    } else if (code === 500) {
      // Message({ message: msg, type: 'error' })
      MessageBox.confirm(msg, 'Tips', {showCancelButton:false, confirmButtonText: 'confirm',type: 'warning' })
      return Promise.reject(new Error(msg))
    } else if (code !== 200) {
      Notification.error({ title: msg})
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  error => {
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "Abnormal connection of back-end interface";
    }
    else if (message.includes("timeout")) {
      message = "System interface request timeout";
    }
    else if (message.includes("Request failed with status code")) {
      message = "system interface" + message.substr(message.length - 3) + "abnormal";
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
export var API_BASE = process.env.VUE_APP_BASE_API
