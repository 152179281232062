import Vue from 'vue';
import VueRouter from 'vue-router';

// 使用插件
Vue.use(VueRouter)

import Login from '@/pages/login';
import Eform from '@/pages/e-form'
import perviewForm from '@/pages/e-form/perviewForm'
//配置路由
export default new VueRouter({
    mode:'history',
    routes:[
        {
            path:'*',//重定向  【 / 或者* 都可以】
            redirect:'/login'
        },
        {
            path:'/login',
            component:Login,
            meta:{
                title:'登錄'
            }
        },
        {
            path:'/eForm',
            component:Eform,
            meta:{
                title:'eForm',
                requireauth:true //判斷是否需要登錄
            }
        },
        {
            path:'/perviewForm',
            component:perviewForm,
            meta:{
                title:'perviewForm',
                requireauth:true //判斷是否需要登錄
            }
        }
    ]
})