<template>
  <div id="app">
    <!-- 
      报错 export 'default' (imported as 'VueRouter').....  查看router版本是否过高  
      Vue CLI 4.5以下，对应的是Vue2
      Vue CLI 4.5及以上，对应的是Vue3，也可以手动选择Vue2 vue
      3.0以下兼容的是element-ui前端组件库;
      vue 3.0兼容的是element-plus前端组件库.
      vue2搭配vue-router3
      vue3搭配vue-router4
    -->
    <!-- 路由 导出 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
.el-loading-spinner .el-loading-text,.el-icon-loading{
  color: #ffffff !important;
  font-size: 18px !important;
}
</style>
